import * as React from "react";
import dayjs from "dayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DAY_FORMAT } from "./BookingWidget";

export default function DateCalendarViews({
  availabilities,
  defaultDay,
  setCurrentDay,
}) {
  const shouldDisableDate = (day) => {
    const formattedDay = dayjs(day).format(DAY_FORMAT);
    return !availabilities[formattedDay];
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        shouldDisableDate={shouldDisableDate}
        day={<PickersDay sx={{ background: "red" }} />}
        disablePast={true}
        showDaysOutsideCurrentMonth={false}
        minDate={dayjs().startOf("month")}
        maxDate={dayjs().add(2, "month")}
        style={{
          margin: "0",
          width: "100%",
        }}
        defaultValue={defaultDay}
        onChange={setCurrentDay}
        views={["day"]}
      />
    </LocalizationProvider>
  );
}
