import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { BookingWidget, SelectionView } from "./components/BookingWidget";

const BASE_URL =
  "https://www.tanzfabrik-berlin.de/api";

document.addEventListener("DOMContentLoaded", () => {
  const domNode = document.getElementById("studio-booking-widget-root");
  const root = createRoot(domNode);
  root.render(<App></App>);
});

const router = createBrowserRouter([
  {
    path: "/:locale/studios",
    element: <BookingWidget />,
    children: [
      {
        path: "selection",
        element: <SelectionView />,
      },
    ],
  },
]);

const App = () => {
  const userId = getCookie("user_id");
  const [state, setState] = useState({ user: null });

  useEffect(() => {
    fetchUser(userId).then((user) => setState((state) => ({ ...state, user })));
  }, []);

  const fetchUser = async (userId) => {
    if (!userId) return null;
    return fetch(`${BASE_URL}/users/current`)
      .then((response) => response.json())
      .then((result) => {
        console.log({ user: result });
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.StrictMode>
      <ApplicationContext.Provider value={state}>
        <RouterProvider router={router} />
      </ApplicationContext.Provider>
    </React.StrictMode>
  );
};

function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

export const ApplicationContext = React.createContext();
