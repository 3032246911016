import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import Calendar from "./Calendar";
import dayjs from "dayjs";
import moment from "moment-timezone";

import { ApplicationContext } from "../react_app";

import studioMapMarkerSvg from "../../svgs/studio-map-marker.svg";
import bookingCalendarSvg from "../../svgs/booking-calendar.svg";

const BASE_URL = "https://www.tanzfabrik-berlin.de/api";
const CALENDAR_AVAILABILITY_URL = `${BASE_URL}/studios`;
const STUDIOS_BY_TYPE_URL = `${BASE_URL}/studios`;
const AVAILABILITY_CHECK_URL = `${BASE_URL}/studios/availabilities-check`;
const SUMMARY_URL = `${BASE_URL}/checkout/summary`;
const CHECKOUT_URL = `${BASE_URL}/checkout/create`;

export const DAY_FORMAT = "YYYY-MM-DD";
const MONTH_FORMAT = "YYY-MM";

const BOOKING_TYPES = [
  {
    value: "one_hour_rehearsal",
    en: "Rehearsal",
    de: "Probe",
  },
  {
    value: "daily_teil_residenz",
    en: "Daily Half residency",
    de: "Teil-residenz (Täglich)",
  },
  {
    value: "weekly_teil_residenz",
    en: "Weekly Half residency",
    de: "Teil-residenz (Wöchentlich)",
  },
];

const MAX_DATE = dayjs().add(2, "month");

export const BookingWidget = () => {
  const { user } = useContext(ApplicationContext);
  const [view, setView] = useState("selection");
  const [availabilities, setAvailabilities] = useState(null);
  const [bookingType, setBookingType] = useState(BOOKING_TYPES[0].value);
  const [availableStudios, setAvailableStudios] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [currentDay, setCurrentDay] = useState(dayjs());
  const [selectionErrorMessage, setSelectionErrorMessage] = useState("");
  const [locale, setLocale] = useState("en");
  const [currentMonth, setCurrentMonth] = useState(null);

  const [displayedAvailabilities, setDislpayedAvailabilities] = useState(null);
  const [selectedTimes, setSelectedTimes] = useState({});

  const handleDateChange = (newDate) => {
    setCurrentDay(newDate);
    setDislpayedAvailabilities(availabilities[newDate.format(DAY_FORMAT)]);
  };

  const handleTimeSelection = (time) => {
    if (selectedTimes[time.id]) {
      delete selectedTimes[time.id];
      setSelectedTimes({ ...selectedTimes });
    } else {
      const data = { ...selectedTimes, [time.id]: time };
      setSelectedTimes(data);
    }
  };

  const fetchStudiosByType = async (type) => {
    const pathSegments = window.location.pathname.split("/");
    const lang = pathSegments[1];
    const currentLanguage = lang === "de" ? "de" : "en";
    return fetch(`${STUDIOS_BY_TYPE_URL}/${type}?language=${currentLanguage}`)
      .then((response) => response.json())
      .then((result) => {
        setAvailableStudios(result);
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const fetchCalendar = async (studioId, booking_type) => {
    try {
      const response = await fetch(
        `${CALENDAR_AVAILABILITY_URL}/${studioId}/availabilities?booking_type=${booking_type}`
      );
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleMonthChange = (increment) => {
    const months = Object.keys(availabilities);
    const currIndex = currentMonth ? months.indexOf(currentMonth) : 0;
    if (
      currIndex + increment >= 0 &&
      currIndex + increment <= months.length - 1
    ) {
      const newIndex = currIndex + increment;
      const newMonth = months[newIndex];
      setCurrentMonth(newMonth);
      setDislpayedAvailabilities(availabilities[newMonth]);
    }
  };

  const handleChangeStudio = (studioId, bookingType) => {
    const selectedStudio = availableStudios.find((s) => s.id == studioId);
    setSelectedStudio(selectedStudio);
    setSelectedTimes({});
    fetchCalendar(studioId, bookingType).then((availabilities) =>
      handleAvailabilities(availabilities, bookingType)
    );
  };
  const handleChangeBookingType = (selectedBookingType) => {
    setBookingType(selectedBookingType);
    setSelectedTimes({});
    fetchStudiosByType(selectedBookingType).then((studios) => {
      setSelectedStudio(studios[0]);
      fetchCalendar(studios[0].id, selectedBookingType).then((availabilities) =>
        handleAvailabilities(availabilities, selectedBookingType)
      );
    });
  };

  const handleAvailabilities = (availabilities, bookingType) => {
    let result = isWeeklyTeilResidenz(bookingType)
      ? mergeByMonth(availabilities)
      : mergeByDay(availabilities);
    setAvailabilities(result);
    let displayedAvailabilities = isWeeklyTeilResidenz(bookingType)
      ? (() => {
        const months = Object.keys(result);

        const currIndex = currentMonth
          ? months.indexOf(currentMonth) == -1
            ? 0
            : months.indexOf(currentMonth)
          : 0;
        setCurrentMonth(months[currIndex]);
        return result[months[currIndex]];
      })()
      : result[currentDay.format(DAY_FORMAT)];
    setDislpayedAvailabilities(displayedAvailabilities);
  };
  useEffect(() => {
    fetchStudiosByType(bookingType).then((studios) => {
      setSelectedStudio(studios[0]);
      fetchCalendar(studios[0].id, bookingType).then((availabilities) =>
        handleAvailabilities(availabilities, bookingType)
      );
    });
  }, []);

  const convertToBerlinTime = (time) => {
    return moment(time).tz("Europe/Berlin").format();
  };

  let updatedDisplyerAvailabilities = displayedAvailabilities;
  if (displayedAvailabilities?.length > 0) {
    updatedDisplyerAvailabilities = displayedAvailabilities.map(
      (availability) => ({
        ...availability,
        start: convertToBerlinTime(availability.start),
        end: convertToBerlinTime(availability.end),
      })
    );
    updatedDisplyerAvailabilities = updatedDisplyerAvailabilities;
  }

  useEffect(() => {
    // Extract locale from the URL
    const path = window.location.pathname;
    const match = path.match(/^\/(en|de)\//);
    if (match) {
      setLocale(match[1]);
    }
  }, []);

  const handleChangeLocale = (newLocale) => {
    setLocale(newLocale);
    // Update the URL to reflect the new locale and add #vergabe
    const newPath =
      window.location.pathname.replace(/^\/(en|de)\//, `/${newLocale}/`) +
      "#vergabe";

    // Update the URL and reload the page
    window.location.href = newPath;
  };

  return (
    <>
      <div className="widget-container" style={{ overflow: "scroll" }}>
        <div style={{ display: "flex" }}>
          {/* Language Switcher */}
          <ul
            id="language_switcher"
            style={{
              display: "flex",
              listStyleType: "none",
              padding: 0,
              margin: 0,
              marginLeft: "auto",
            }}
          >
            <li
              className={locale === "en" ? "active" : ""}
              onClick={() => locale !== "en" && handleChangeLocale("en")}
              style={{
                margin: "0 0.5rem",
                cursor: locale === "en" ? "default" : "pointer",
                textDecoration: locale === "en" ? "none" : "underline",
                position: "relative",
              }}
            >
              {locale === "en" ? "EN" : "EN"}
            </li>
            /
            <li
              className={locale === "de" ? "active" : ""}
              onClick={() => locale !== "de" && handleChangeLocale("de")}
              style={{
                margin: "0 0.5rem",
                cursor: locale === "de" ? "default" : "pointer",
                textDecoration: locale === "de" ? "none" : "underline",
                position: "relative",
              }}
            >
              {locale === "de" ? "DE" : "DE"}
            </li>
          </ul>
        </div>
        <div
          className="widget-content"
          style={{ minWidth: "38rem", overflowY: "scroll" }}
        >
          {view === "selection" ? (
            <SelectionView
              user={user}
              setView={setView}
              currentDay={currentDay}
              bookingType={bookingType}
              currentMonth={currentMonth}
              selectedTimes={selectedTimes}
              selectedStudio={selectedStudio}
              availabilities={availabilities}
              availableStudios={availableStudios}
              displayedAvailabilities={updatedDisplyerAvailabilities}
              handleDateChange={handleDateChange}
              handleMonthChange={handleMonthChange}
              handleChangeStudio={handleChangeStudio}
              handleTimeSelection={handleTimeSelection}
              handleChangeBookingType={handleChangeBookingType}
              selectionErrorMessage={selectionErrorMessage}
            />
          ) : (
            <SummaryView
              setView={setView}
              selectedTimes={selectedTimes}
              selectedStudio={selectedStudio}
              bookingType={bookingType}
              setSelectionErrorMessage={setSelectionErrorMessage}
            />
          )}
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="contact-info">
        {locale === "de" ? (
          <>
            <p>↪ KONTAKT</p>
            <br />
            <div className="contact-details">
              <div style={{ width: "45%" }}>
                <p>Fragen zur Probe (stündlich)</p>
                <p>Ludivine Thiel</p>
                <p>
                  Email:{" "}
                  <a href="mailto:proberaum@tanzfabrik-berlin.de">
                    proberaum@tanzfabrik-berlin.de
                  </a>
                </p>
                <p>Fon: +49(0)3078895339</p>
                <p>Studiobuchungen möglich via Mail.</p>
                <br />

                <p>Montag: 10:00–13:00 (hybrid)</p>
                <p>Dienstag: 16:30–18:00 (online)</p>
                <p>Freitag: 09:00–12:00 (online)</p>
                <br />

                <p>
                  Sprechzeiten können sowohl vor Ort als auch via Zoom
                  stattfinden.
                </p>
                <p>↪ Zoom-Konferenz: link</p>
                <p>↪ Vor Ort Termin: link</p>
                <br />

                <p>Achtung, keine Sprechzeiten am:</p>
                <p>Freitag, 06.10.23, Montag, 09.10.23, Dienstag, 07.11.23</p>
                <br />
                <p>
                  <em>* außer an gesetzlichen Feiertagen</em>
                </p>
              </div>

              <div style={{ width: "45%" }}>
                <p>Fragen zur Teil-residenz</p>
                <p>Vincenz Kokot</p>
                <p>
                  Email:{" "}
                  <a href="mailto:organisation@tanzfabrik-berlin.de">
                    organisation@tanzfabrik-berlin.de
                  </a>
                </p>
                <p>Fon: +49(0)3020059272</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <p>↪ CONTACT</p>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "45%" }}>
                <p>Questions about Rehearsal (Hourly)</p>
                <p>Ludivine Thiel</p>
                <p>
                  Email:{" "}
                  <a href="mailto:proberaum@tanzfabrik-berlin.de">
                    proberaum@tanzfabrik-berlin.de
                  </a>
                </p>
                <p>Phone: +49(0)3078895339</p>
                <p>Studio bookings possible via mail.</p>
                <br />

                <p>Monday: 10:00–13:00 (hybrid)</p>
                <p>Tuesday: 16:30–18:00 (online)</p>
                <p>Friday: 09:00–12:00 (online)</p>
                <br />

                <p>Office hours can be held both on-site and via Zoom.</p>
                <p>↪ Zoom-conference: link</p>
                <p>↪ On-site appointment: link</p>
                <br />

                <p>Attention, no office hours on:</p>
                <p>Friday 06.10.23, Monday 09.10.23, Tuesday 07.11.23</p>
                <br />
                <p>
                  <em>* except on legal holidays</em>
                </p>
              </div>

              <div style={{ width: "45%" }}>
                <p>Questions about Half-residency</p>
                <p>Vincenz Kokot</p>
                <p>
                  Email:{" "}
                  <a href="mailto:organisation@tanzfabrik-berlin.de">
                    organisation@tanzfabrik-berlin.de
                  </a>
                </p>
                <p>Phone: +49(0)3020059272</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const SelectionView = ({
  user,
  setView,
  currentDay,
  bookingType,
  currentMonth,
  selectedTimes,
  selectedStudio,
  availabilities,
  availableStudios,
  displayedAvailabilities,
  handleDateChange,
  handleMonthChange,
  handleChangeStudio,
  handleTimeSelection,
  handleChangeBookingType,
  selectionErrorMessage,
}) => {
  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";
  return (
    <>
      <div className="left-pane selection-view">
        <p className="title">
          <strong>
            {currentLanguage === "en" ? "Studio Booking" : "Studiobuchung"}
          </strong>
        </p>
        <Selection
          availableStudios={availableStudios}
          bookingType={bookingType}
          handleChangeStudio={handleChangeStudio}
          handleChangeBookingType={handleChangeBookingType}
          selectedStudio={selectedStudio}
        />
        {selectedStudio && (
          <>
            <img
              src={selectedStudio.image.url}
              alt={selectedStudio.image.url}
            />
            <p className="studio-title">
              {selectedStudio.name} <br />
              {selectedStudio.address}
            </p>
            <div
              className="trix-content"
              dangerouslySetInnerHTML={{
                __html: selectedStudio.description,
              }}
            />
          </>
        )}
      </div>
      <div className="right-pane selection-view">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          <div style={{ margin: "1rem 1rem 0 1rem" }}>
            <p className="title">
              <strong>
                {currentLanguage === "en"
                  ? "Select a Time & Date"
                  : "Wählen Sie eine Uhrzeit und ein Datum aus"}
              </strong>
            </p>
            {bookingType === "daily_teil_residenz" ? (
              <p>
                <strong>
                  {" "}
                  {currentLanguage === "en"
                    ? `Timings per day: "10am to 2pm" & "2pm to 5pm"`
                    : "Studiozeiten pro Tag: “10Uhr bis 14Uhr” & “14Uhr bis 17Uhr"}
                </strong>
              </p>
            ) : bookingType === "weekly_teil_residenz" &&
              selectedStudio?.name === "Kreuzberg 4" ? (
              <p>
                <strong>
                  {currentLanguage === "en"
                    ? "Timings per day: From 10am till 5pm"
                    : "Studiozeiten pro Tag: von 10Uhr bis 17Uhr"}
                </strong>
              </p>
            ) : bookingType === "weekly_teil_residenz" &&
              (selectedStudio?.name === "Uferstudios 4" ||
                selectedStudio?.name === "Uferstudios 5") ? (
              <p>
                <strong>
                  {" "}
                  {currentLanguage === "en"
                    ? "Timings per day: From 10am till 8pm"
                    : "Studiozeiten pro Tag: von 10Uhr bis 20Uhr"}
                </strong>
              </p>
            ) : null}

            {bookingType === "daily_teil_residenz" || bookingType === "weekly_teil_residenz" ? (
              <p style={{ marginTop: "2%" }}>
                <strong>
                  {currentLanguage === "en"
                    ? "The studios and studio times of half-residency are exclusively for rehearsals and artistic research"
                    : "Die Studios und Zeiten der Teilresidenz sind ausschließlich für die künstlerische Recherche sowie die Arbeit an Stücken vorbehalten"}
                </strong>
              </p>
            ) : null}



          </div>
        </div>
        <div className="upper-section">
          {isWeeklyTeilResidenz(bookingType) ? (
            <WeeklyView
              availabilities={availabilities}
              selectedTimes={selectedTimes}
              currentMonth={currentMonth}
              displayedAvailabilities={displayedAvailabilities}
              handleMonthChange={handleMonthChange}
              handleTimeSelection={handleTimeSelection}
              selectionDisabled={
                !user || !userIsAllowedToBook(user, bookingType)
              }
            ></WeeklyView>
          ) : (
            <CalendarView
              availabilities={availabilities}
              currentDay={currentDay}
              handleDateChange={handleDateChange}
              displayedAvailabilities={displayedAvailabilities}
              handleTimeSelection={handleTimeSelection}
              selectedTimes={selectedTimes}
              selectionDisabled={
                !user || !userIsAllowedToBook(user, bookingType)
              }
              bookingType={bookingType}
            ></CalendarView>
          )}
        </div>
        <div className="lower-section">
          {!user ? (
            <NextBtn
              user={user}
              setView={setView}
              bookingType={bookingType}
              selectedTimes={selectedTimes}
            ></NextBtn>
          ) : null}

          {user && userIsAllowedToBook(user, bookingType) ? (
            <SelectedTimes
              times={selectedTimes}
              handleClick={handleTimeSelection}
              bookingType={bookingType}
              selectionErrorMessage={selectionErrorMessage}
            />
          ) : null}

          {user ? (
            <NextBtn
              user={user}
              setView={setView}
              bookingType={bookingType}
              selectedTimes={selectedTimes}
            ></NextBtn>
          ) : null}
        </div>
      </div>
    </>
  );
};

const SummaryView = ({
  setView,
  selectedTimes,
  selectedStudio,
  bookingType,
  setSelectionErrorMessage,
}) => {
  const { user } = useContext(ApplicationContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [summary, setSummary] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [teilResidence, setTeilResidence] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDescription, setProjectDescription] = useState("");

  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";

  useEffect(() => {
    fetchSummary({
      selectedTimes,
      selectedStudio,
      bookingType,
      setSelectionErrorMessage,
      setView,
    }).then((summary) => setSummary(summary));
  }, []);

  return (
    <>
      <div className="left-pane summary-view">
        {!teilResidence ? (
          <div className="header summary-view">
            <BackBtn
              setView={setView}
              setIsCompleted={setIsCompleted}
              isCompleted={isCompleted}
            ></BackBtn>
          </div>
        ) : (
          <></>
        )}

        <p className="title">
          <strong>
            {currentLanguage === "en" ? "Summary" : "Zusammenfassung"}
          </strong>
        </p>
        <p className="title">
          <strong>{currentLanguage === "en" ? "Total Price:" : "Gesamtpreis:"}{summary && summary.cart.price}&euro;</strong>
        </p>
        <div className="bookings-container">
          {summary &&
            summary.cart.bookings
              .sort((a, b) => a.start < b.start)
              .map((b, i) => (
                <BookingCard
                  cart={summary.cart}
                  key={i}
                  index={i}
                  booking={b}
                ></BookingCard>
              ))}
        </div>
      </div>
      <div className="right-pane summary-view">
        {!teilResidence ? (
          <div className="header">
            <p className="title" style={{ marginBottom: "0" }}>
              <strong>
                {currentLanguage === "en" ? "Review Details:" : "Details prüfen:"}
              </strong>
            </p>
            <a
              href="/users/edit"
              className="bold-link"
              style={{ width: "fit-content", alignSelf: "center" }}
            >
              {currentLanguage === "en" ? "Edit details →" : "Details bearbeiten →:"}
            </a>
          </div>
        ) : (
          <></>
        )}

        {teilResidence ? (
          <strong className="title booking-error">
            {" "}
            Admin will review the booking once it's approved, you will receive
            an email with a payment link.
          </strong>
        ) : (
          <UserDetails
            user={user}
            bookingType={bookingType}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setErrorMessage={setErrorMessage}
            termsChecked={termsChecked}
            setTermsChecked={setTermsChecked}
            setProjectDescription={setProjectDescription}
          />
        )}

        {!teilResidence ? (
          <CheckoutBtn
            summary={summary}
            total={summary?.cart?.price}
            setIsCompleted={setIsCompleted}
            setTeilResidence={setTeilResidence}
            name={
              currentLanguage === "en"
                ? (bookingType === "one_hour_rehearsal" ? "Checkout" : "Submit Request")
                : (bookingType === "one_hour_rehearsal" ? "Checkout" : "Anfrage senden")
            }
            onClickMethod={handleClick}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setBookingId={setBookingId}
            bookingId={bookingId}
            termsChecked={termsChecked}
            setSelectionErrorMessage={setSelectionErrorMessage}
            setView={setView}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            projectDescription={projectDescription}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const UserDetails = ({
  user,
  bookingType,
  selectedOption,
  setSelectedOption,
  setErrorMessage,
  termsChecked,
  setTermsChecked,
  setProjectDescription,
}) => {
  const handleTermsChange = (event) => {
    if (event.target.checked) {
      setTermsChecked(true);
    } else {
      setTermsChecked(false);
    }
  };

  const handleProjectDescriptionChange = (event) => {
    setProjectDescription(event.target.value); // update state with the textarea value
  };

  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";

  return (
    <>
      <p style={{ marginBottom: "0.25rem" }}>
        <strong>
          {currentLanguage === "en" ? "First name:" : "Vorname:"}
        </strong> {user.first_name}
      </p>
      <p style={{ marginBottom: "0.25rem" }}>
        <strong> {currentLanguage === "en" ? "Last name:" : "Nachname:"}</strong> {user.last_name}
      </p>
      <p style={{ marginBottom: "0.25rem" }}>
        <strong> {currentLanguage === "en" ? "Email:" : "E-Mail:"}</strong> {user.email}
      </p>
      <p style={{ marginBottom: "0.25rem" }}>
        <strong> {currentLanguage === "en" ? "Phone number:" : "Telefonnummer:"}</strong> {user.phone_number}
      </p>

      {isTeilResidenz(bookingType) && (
        <p style={{ marginBottom: "0.25rem" }}>
          <strong>{currentLanguage === "en" ? "Tax number:" : "Steuernummer:"}</strong> {user.tax_number}
        </p>
      )}

      {isTeilResidenz(bookingType) && (
        <fieldset style={{ marginTop: "1rem" }}>
          <strong>{currentLanguage === "en" ? "Project description:" : "Projektbeschreibung:"}</strong>
          <p className="rich_content_3">
            {currentLanguage === "en" ? "A Short project description, including title and amount of people involved." : "Eine kurze Projektbeschreibung, einschließlich Titel und Anzahl der beteiligten Personen."}
          </p>
          <textarea
            id="project_description"
            name="project_description"
            autoFocus={true}
            autoComplete="project-description"
            className="text-area-field"
            maxLength={700}
            onChange={handleProjectDescriptionChange}
          />
        </fieldset>
      )}

      <fieldset style={{ margin: "2rem 0" }}>
        <strong>
          {" "}
          {currentLanguage === "en"
            ? "Terms and conditions"
            : "Allgemeinen Geschäftsbedingungen & Nutzungsordnung"}
        </strong>

        {currentLanguage === "en" ? (
          isTeilResidenz(bookingType) ? (
            <p style={{ marginBottom: "1rem" }}>
              The studio may only be used for artistic rehearsals.
              <br />
              Wearing shoes on the dance floor/carpet is not allowed.
              <br />
              Food/eating in the studio is not allowed.
              <br />
              Loud or live music is not possible in the Kreuzberg studio and in
              Wedding Studios only permitted with prior approval from the
              Tanzfabrik (max. volume of 80 db, no bass frequencies).
              <br />
              All doors and windows must remain closed while sound is being used
              in the Kreuzberg studio.
              <br />
              No objects, stage design, clothes, personal things etc. can be
              left at the Kreuzberg Studio overnight.
              <br />
            </p>
          ) : (
            <p style={{ marginBottom: "1rem" }}>
              The studio may only be used for artistic rehearsals.
              <br />
              Wearing shoes inside the studio is not allowed.
              <br />
              Live music is only permitted with prior approval from the
              Tanzfabrik.
              <br />
              All windows must remain closed while sound is being used in the
              studio.
              <br />
              Cancellation of the booking is not possible.
              <br />
              However, the booking can be rescheduled up to 24 hours in advance,
              within 8 weeks from the booking date, without limitation.
              <br />
            </p>
          )
        ) : isTeilResidenz(bookingType) ? (
          <p style={{ marginBottom: "1rem" }}>
            Das Studio darf nur für künstlerische Proben genutzt werden.
            <br />
            Das Tragen von Schuhen auf dem Tanzboden/Teppich ist nicht erlaubt.
            <br />
            Essen/im Studio zu essen ist nicht erlaubt.
            <br />
            Lautstärke oder Live-Musik sind im Kreuzberger Studio nicht möglich
            und in den Wedding Studios nur mit vorheriger Genehmigung der
            Tanzfabrik gestattet (max. Lautstärke von 80 dB, keine
            Bassfrequenzen).
            <br />
            Alle Türen und Fenster müssen geschlossen bleiben, während im
            Kreuzberger Studio Ton verwendet wird.
            <br />
            Keine Gegenstände, Bühnenbilder, Kleidung, persönlichen Dinge usw.
            dürfen über Nacht im Kreuzberger Studio gelassen werden.
            <br />
          </p>
        ) : (
          <p style={{ marginBottom: "1rem" }}>
            Das Studio darf nur für künstlerische Proben genutzt werden.
            <br />
            Schuhe dürfen im Studio nicht getragen werden.
            <br />
            Live-Musik ist nur nach vorheriger Absprache mit der Tanzfabrik
            gestattet.
            <br />
            Alle Fenster müssen geschlossen bleiben, während das Studio
            beschallt wird.
            <br />
            Eine Stornierung der Buchung ist ausgeschlossen.
            <br />
            Die Buchung kann jedoch bis zu 24 Stunden im Voraus innerhalb eines
            Zeitraums von 8 Wochen ab dem Buchungsdatum beliebig oft umgebucht
            werden.
            <br />
          </p>
        )}

        <div className="field">
          <CheckboxWithLabel
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setErrorMessage={setErrorMessage}
            termsChecked={termsChecked}
            setTermsChecked={setTermsChecked}
            handleTermsChange={handleTermsChange}
            currentLanguage={currentLanguage}
          />
        </div>
      </fieldset>
    </>
  );
};

const CheckboxWithLabel = ({
  name,
  termsChecked,
  handleTermsChange,
  currentLanguage,
}) => {
  const termsLink = "/terms_and_conditions.pdf";
  return (
    <label className="checkbox-label">
      <input
        type="checkbox"
        name={name}
        className="checkbox-input"
        checked={termsChecked}
        onChange={handleTermsChange}
      />
      <span>
        {currentLanguage === "en" ? (
          <>
            I agree to the{" "}
            <a
              href={termsLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              terms and conditions
            </a>
            .
          </>
        ) : (
          <>
            Ich stimme den{" "}
            <a
              href={termsLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              Allgemeinen Geschäftsbedingungen
            </a>{" "}
            zu.
          </>
        )}
      </span>
    </label>
  );
};

const BookingCard = ({ index, booking, cart }) => {
  return (
    <div className="booking-card">
      <div className="row">
        <span
          style={{
            width: "42px",
            padding: "0 0.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ marginBottom: "0" }} className="rich_content_2">
            {index + 1}.
          </p>
        </span>
        <p style={{ marginBottom: "0" }} className="rich_content_2">
          {cart.booking.display_name}
        </p>
      </div>

      <div className="row">
        <span
          style={{
            height: "32px",
            width: "42px",
            padding: "0 0.25rem",
          }}
        >
          <img src={studioMapMarkerSvg} alt="Studio Map Marker" />
        </span>

        <p className="rich_content_3">
          {booking.studio.name} <br /> {booking.studio.address}
        </p>
      </div>
      <div className="row">
        <span
          style={{
            width: "42px",
            padding: "0 0.5rem",
          }}
        >
          <img src={bookingCalendarSvg} alt="Studio Map Marker" />
        </span>

        {isWeeklyTeilResidenz(cart.booking.type) ? (
          <p className="rich_content_3">
            {dayjs(booking.start).format("ddd, MMMM D")} -{" "}
            {dayjs(booking.end).format("ddd, MMMM D")}
          </p>
        ) : (
          <p className="rich_content_3">
            {dayjs(booking.start).format("ddd, MMMM D")} <br />{" "}
            {dayjs(booking.start).format("LT")} -{" "}
            {dayjs(booking.end).format("LT")}
          </p>
        )}
      </div>
    </div>
  );
};

const WeeklyView = ({
  currentMonth,
  selectedTimes,
  handleTimeSelection,
  displayedAvailabilities,
  handleMonthChange,
  selectionDisabled,
}) => {
  return (
    <div className="weekly-view-container">
      <div className="header">
        <span
          style={{ paddingRight: "1rem", cursor: "pointer" }}
          onClick={() => handleMonthChange(-1)}
        >
          ❮
        </span>
        <p className="rich_content_2">
          <strong>{dayjs(currentMonth).format("MMMM YYYY")}</strong>
        </p>
        <span
          style={{ paddingLeft: "1rem", cursor: "pointer" }}
          onClick={() => handleMonthChange(1)}
        >
          ❯
        </span>
      </div>
      <div className="available-weeks">
        {displayedAvailabilities &&
          displayedAvailabilities.map((time) => {
            const updatedTime =
              dayjs(time.start).format("DD.MM.YYYY") +
              " to " +
              dayjs(time.end).format("DD.MM.YYYY");
            return (
              <TimeSlotBtn
                key={time.id}
                time={time}
                selectionDisabled={selectionDisabled}
                displayedTime={updatedTime}
                handleClick={handleTimeSelection}
                selected={selectedTimes[time.id]}
                style={{ width: "21rem" }}
              />
            );
          })}
      </div>
    </div>
  );
};

const CalendarView = ({
  availabilities,
  currentDay,
  handleDateChange,
  displayedAvailabilities,
  handleTimeSelection,
  selectedTimes,
  selectionDisabled,
  bookingType,
}) => {
  return (
    <>
      <div className="calendar-container">
        {availabilities && (
          <Calendar
            availabilities={availabilities}
            defaultDay={currentDay}
            setCurrentDay={handleDateChange}
            maxDate={MAX_DATE}
          />
        )}
      </div>
      <div className="available-times">
        <p>{currentDay.format("dddd MMMM D")}</p>
        {displayedAvailabilities &&
          displayedAvailabilities.map((time) => {
            return (
              <TimeSlotBtn
                key={time.id}
                time={time}
                displayedTime={dayjs(time.start).format("LT")}
                endTime={dayjs(time.end).format("LT")}
                handleClick={handleTimeSelection}
                selected={selectedTimes[time.id]}
                selectionDisabled={selectionDisabled}
                bookingType={bookingType}
              />
            );
          })}
      </div>
    </>
  );
};

const handleClick = ({
  summary,
  setIsCompleted,
  setTeilResidence,
  total,
  setErrorMessage,
  setBookingId,
  setSelectionErrorMessage,
  setView,
  setIsLoading,
  projectDescription,
}) => {
  summary.cart.projectDescription = projectDescription;

  createBooking({ summary })
    .then(async (data) => {
      if (data?.krk_paid) {
        window.location.reload();
        window.location.href = "/en/studios#vergabe";
      }
      setIsCompleted(true);
      setBookingId(data?.id);
      setIsLoading(false);

      const availabilityResponse = await fetch(AVAILABILITY_CHECK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          booking: summary.cart.booking,
          bookings: summary.cart.bookings,
        }),
      });

      if (!availabilityResponse.ok) {
        const errorData = await availabilityResponse.json();
        setSelectionErrorMessage(
          errorData.error || "Failed to check availability."
        );
        setView("selection");
        return;
      }

      setSelectionErrorMessage("");

      window.location.href = `/payments/new?total=${total}&type=paypal&bookingId=${data?.id}`;
    })
    .catch((error) => {
      setIsLoading(false);
      if (error.message.includes("payment link")) {
        setTeilResidence(true);

        setTimeout(() => {
          setTeilResidence(false);
          setErrorMessage("");
          window.location.reload();
        }, 5000);
      } else if (error.message.includes("slots")) {
        setSelectionErrorMessage(error.message);
        setView("selection");
      }

      setErrorMessage(error.message);
    });
};

const CheckoutBtn = ({
  total,
  summary,
  setIsCompleted,
  name,
  onClickMethod,
  selectedOption,
  errorMessage,
  setErrorMessage,
  setBookingId,
  bookingId,
  termsChecked,
  setTeilResidence,
  setSelectionErrorMessage,
  setView,
  setIsLoading,
  isLoading,
  projectDescription,
}) => {
  const handleClick = () => {
    if (!termsChecked) {
      setErrorMessage("Please agree to the terms and conditions to proceed.");
    } else {
      setErrorMessage("");
      setIsLoading(true);

      onClickMethod({
        summary,
        setIsCompleted,
        setTeilResidence,
        total,
        selectedOption,
        setErrorMessage,
        setBookingId,
        bookingId,
        setSelectionErrorMessage,
        setView,
        setIsLoading,
        projectDescription,
      });
    }
  };

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <button
        type="button"
        className={`checkout-btn-custom ${isLoading ? "disabled" : ""}`}
        onClick={handleClick}
        disabled={isLoading}
      >
        {isLoading ? <span className="loader"></span> : <strong>{name}</strong>}
      </button>
    </div>
  );
};

const BackBtn = ({ setView, setIsCompleted, isCompleted }) => {
  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";

  return (
    <button
      type="button"
      className="next-btn"
      onClick={() => {
        if (isCompleted) setIsCompleted(false);
        else if (!isCompleted) setView("selection");
      }}
    >
      <strong>&#8592; {currentLanguage === "en" ? "Back" : "Zurück"}</strong>
    </button>
  );
};

const userIsAllowedToBook = (user, bookingType) => {
  return user.permissions.includes(bookingType);
};

const NextBtn = ({ user, bookingType, setView, selectedTimes }) => {
  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";
  if (user && userIsAllowedToBook(user, bookingType)) {
    return (
      <>
        <button
          type="button"
          className="next-btn"
          onClick={() => {
            Object.entries(selectedTimes).length > 0 && setView("summary");
          }}
        >
          <strong>
            {currentLanguage === "en" ? "Next Step →" : "nächster Schritt →"}
          </strong>
        </button>
      </>
    );
  }
  if (user && !userIsAllowedToBook(user, bookingType)) {
    return (
      <>
        <div className="login-reminder">
          <p>{currentLanguage === "en" ? "You must request permission to book." : "Zum Buchen müssen Sie eine Erlaubnis anfragen."}</p>
          <button
            onClick={() => {
              window.location.href = "/users/edit";
            }}
            type="button"
            className="next-btn next-login-btn"
          >
            <strong>{currentLanguage === "en" ? "Request permission" : "Erlaubnis anfragen"} &#8594;</strong>
          </button>
        </div>
      </>
    );
  }

  if (!user) {
    return (
      <>
        <div className="login-reminder">
          <p>
            {" "}
            {currentLanguage === "en"
              ? "Please firstly login to book"
              : "Bitte melden Sie sich zunächst an, um zu buchen"}{" "}
          </p>
          <button
            onClick={() => {
              window.location.href = "/users/sign_in";
            }}
            type="button"
            className="next-btn next-login-btn"
          >
            <strong>Log in &#8594;</strong>
          </button>
        </div>
      </>
    );
  }

  return <></>;
};

const Selection = ({
  availableStudios,
  bookingType,
  handleChangeStudio,
  handleChangeBookingType,
  selectedStudio,
}) => {
  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";

  return (
    <div className="studio-selection-container">
      <label htmlFor="booking-type">
        <p className="rich_content_2">
          {currentLanguage === "en" ? "Booking type" : "Buchungsart"}
        </p>
      </label>
      <select
        id="booking-type"
        name="booking-type"
        className="select-field"
        value={bookingType}
        onChange={(e) => {
          handleChangeBookingType(e.target.value);
        }}
      >
        {BOOKING_TYPES.map((bt) => (
          <option key={bt.value} value={bt.value}>
            {bt[currentLanguage]}
          </option>
        ))}
      </select>

      <label htmlFor="studio-select">
        <p className="rich_content_2">Studio</p>
      </label>
      <select
        id="studio-select"
        name="studio-select"
        className="select-field"
        value={selectedStudio ? selectedStudio.id : ""}
        onChange={(e) => {
          handleChangeStudio(e.target.value, bookingType);
        }}
      >
        {availableStudios &&
          availableStudios.map((studio) => (
            <option key={studio.id} value={studio.id}>
              {studio.name}
            </option>
          ))}
      </select>
    </div>
  );
};

const SelectedTimes = ({
  times,
  handleClick,
  bookingType,
  selectionErrorMessage,
}) => {
  const pathSegments = window.location.pathname.split("/");
  const lang = pathSegments[1];
  const currentLanguage = lang === "de" ? "de" : "en";

  const entries = Object.entries(times).sort((a, b) => a.start < b.start);

  return (
    <>
      <p className="rich_content_2">
        {currentLanguage === "en" ? "Currently picked" : "aktuell ausgewählt"}
        {selectionErrorMessage && (
          <span className="error_message"> ({selectionErrorMessage})</span>
        )}
      </p>
      <div className="selection-container">
        {entries.map(([id, time]) => {
          return (
            <button
              key={`x-${id}-x`}
              className={classNames("selected-time")}
              type="button"
              onClick={() => handleClick(time)}
            >
              {isWeeklyTeilResidenz(bookingType) ? (
                <>
                  {dayjs(time.start).format("ddd, MMMM D")} -{" "}
                  {dayjs(time.end).format("ddd, MMMM D")} <span>✖</span>
                </>
              ) : (
                <>
                  {dayjs(time.start).format("ddd MMMM D")} |{" "}
                  {dayjs(time.start).format("LT")} -{" "}
                  {dayjs(time.end).format("LT")} <span>✖</span>
                </>
              )}
            </button>
          );
        })}
      </div>
    </>
  );
};

const Modal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        <div className="modal-content">
          <div className="login-reminder">
            <p>Please firstly login to book</p>
            <button
              onClick={() => {
                window.location.href = "/users/sign_in";
              }}
              type="button"
              className="next-btn next-login-btn"
            >
              <strong>Log in &#8594;</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TimeSlotBtn = ({
  time,
  displayedTime,
  handleClick,
  endTime,
  selected,
  style,
  selectionDisabled,
  bookingType,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    if (selectionDisabled) {
      setShowModal(true); // Show modal if selection is disabled
    } else {
      handleClick(time); // Handle click if selection is enabled
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        type="button"
        style={style}
        className={`select-time-btn ${selected ? "selected" : ""} ${selectionDisabled ? "disabled" : ""
          }`}
        key={time.id}
      >
        {bookingType === "daily_teil_residenz"
          ? `${displayedTime} - ${endTime}`
          : displayedTime}
      </button>

      {showModal && <Modal onClose={closeModal} />}
    </>
  );
};

const isTeilResidenz = (bookingType) => bookingType.includes("teil_residenz");

const isWeeklyTeilResidenz = (bookingType) =>
  bookingType == "weekly_teil_residenz";

function mergeByDay(availabilities) {
  const groupedIntervals = {};

  availabilities.forEach((interval, index) => {
    const startTime = new Date(interval[0]);
    const day = startTime.toISOString().slice(0, DAY_FORMAT.length);
    if (!groupedIntervals[day]) {
      groupedIntervals[day] = [];
    }
    groupedIntervals[day].push({
      id: index,
      start: interval[0],
      end: interval[1],
    });
  });

  return groupedIntervals;
}

function mergeByMonth(availabilities) {
  const groupedIntervals = {};
  availabilities.forEach((interval, index) => {
    const startTime = new Date(interval[0][0]);
    const month = startTime.toISOString().slice(0, MONTH_FORMAT.length + 1); // Extract year and month
    if (!groupedIntervals[month]) {
      groupedIntervals[month] = [];
    }
    groupedIntervals[month].push({
      id: index,
      start: interval[0][0],
      end: interval[interval.length - 1][1],
    });
  });
  return groupedIntervals;
}

const createBooking = async ({ summary }) => {
  try {
    const response = await fetch(CHECKOUT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        total: summary?.cart?.price,
        booking: summary.cart.booking,
        bookings: summary.cart.bookings,
        project_description: summary.cart.projectDescription,
      }),
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || "Booking creation failed");
    }

    return result;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to be caught in handleClick
  }
};

const fetchSummary = async ({
  selectedTimes,
  selectedStudio,
  bookingType,
  setSelectionErrorMessage,
  setView,
}) => {
  return fetch(SUMMARY_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      booking_type: bookingType,
      studio_id: selectedStudio.id,
      bookings: Object.values(selectedTimes).map((t) => ({
        start: t.start,
        end: t.end,
      })),
    }),
  })
    .then((response) => {
      if (!response.ok) {
      }

      if (!response.ok) {
        // If the response is not OK, parse the error message
        return response.json().then((result) => {
          setView("selection");
          setSelectionErrorMessage(result.error);
          return Promise.reject("error");
        });
      }
      setSelectionErrorMessage("");
      return response.json(); // If response is OK, parse and return the JSON
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
